/* color palette https://www.color-hex.com/color-palette/7680*/

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #53585A;
}

body {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.accent {
  color: 	#1e73be !important;
}

.hero {
  height: 100vh;
  width: 100%;
  margin-top: -100px;
  background: 
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.0),
      rgba(0, 0, 0, 0.3)
    ), 
    url('./assets/images/hero2k.jpg') no-repeat center top;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.down-arrow {
  height: auto;
  width: 50px;

  filter: invert(100%);
  margin-bottom: 50px;
  cursor: pointer;
}

.heroText {
  margin-left: 5vw;
  font-size: 2rem;
}
.content {
  display: flex;
  padding: 5vw 15vw;
}

.content-title {
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
  flex-basis: 40%;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
}

.content-title > * {
  margin: 0.5rem 0;
}

.content-title > h2 {
  margin-top: 0;
}

.content-copy {
  font-size: 1rem;
  letter-spacing: 1px;
  flex-basis: 60%
}

.content-copy > p {
  margin-top: 0
}

.exampleWork {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0vw 15vw;
}

.exampleWorkGrid {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  padding: 0vw 15vw;
}

.testimonials {
  font-style: italic;
  list-style: none;
}

.testimonials span {
  font-style: normal;
  position: relative;
  left: 1rem
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardImage > img {
  width: 100%;
  height: auto;
  max-height: 280px;

}

.cardCopy {
  text-align: center;
}


.contact {
  background-color: #29465b;
  color: white;
  padding: 1vw 20vw 1vw 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.contactLinks > * {
  cursor: pointer;
}

.contactLinks > *:hover {
  text-decoration: underline;
}

.footer {
  background-color: #29465b;
  color: white;
  text-align: center;
}

@media screen and (min-width: 2100px) {
  .content-copy {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 2100px) {
  .content-title {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1280px) { /*half web page*/
  .hero {
    height: 70vh;
  }
  .content-title {
    font-size: 1.2rem;
  }
  .cardImage > img {
    max-height: 150px;
  }
}

@media screen and (max-width: 1200px) { 
  .hero {
    height: 50vh;
  }
  .content {
    flex-direction: column;
    padding: 10vw 15vw
  }
  .down-arrow {
    margin-bottom: 30px;
    width: 40px;
  }
}

@media screen and (max-width: 768px) { 
  .exampleWorkGrid {
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .cardImage > img {
    max-height: 300px;
  }
}

@media screen and (max-width: 580px) { 
  .navLogo {
    font-size: 0.8rem !important;
  }
  .svgLogo {
    height: 20px !important;
    width: 20px !important;
  }
  .content-title {
    font-size: 1rem;
  }
  .exampleWorkGrid {
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .content {
    padding: 10vw 12vw
  }
}

@media screen and (max-width: 400px) { 

}

/*
iPhone X: 375 x 812
ipad: 768 x 1024 
*/