.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 10vw;;
  color: white;
  z-index: 1001;
  transition: color 0.2s;
  transition: background-color 0.2s;
}

.navbar.scroll {
  background-color: #f9f9f9;
  color: #53585A;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2);
}

.navLogo {
  /* margin-right: -8vw; */
  font-size: 1.2rem;
  cursor: pointer;
  text-shadow: 0 3px 3px -2px rgba(0,0,0,.2);
}

.svgLogo {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
}

.navLinks {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.navLinks > * {
  margin: 10px;
}
.navLinks > *:hover {
  cursor: pointer;
  text-decoration: underline;
}

.flyoutMenu { /* https://www.kirupa.com/html5/creating_a_smooth_sliding_menu.htm */
  width: 0px;
  height: 100vh;
  background-color: #29465b;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  transition: width 0.5s;
  z-index: 1000;
  overflow: hidden;
}

.flyoutMenu.show {
  width: 240px;
}

.flyoutMenuLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
}

.flyoutMenuLinks h1 {
  transition: transform .2s;
}

.flyoutMenuLinks h1:hover {
  cursor: pointer;
  text-decoration: underline;
  transform: scale(1.1)
}

.flyoutMenuLinks .social-links {
  display: flex;
  justify-content: center;
}

.flyoutMenuLinks img {
  margin: 50px 1rem;
  cursor: pointer;
  transition: transform .2s;
}

.flyoutMenuLinks img:hover {
  transform: scale(1.3)
}

@media screen and (max-width: 1280px) { /*half web page*/
  .navLogo {
    font-size: 1rem;
  } 
  .navLinks {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1140px) { /*ipad landscape*/
  .flyoutMenu.show {
    display: none
  }

  .flyoutMenu.show {
    display: none
  }
}  

@media screen and (max-width: 700px) {
  .flyoutMenu.show {
    width: 100vw;
  }
}

@media screen and (max-width: 580px) {
  .navbar {
    height: 80px;
  }
}



